import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body pb-0" }
const _hoisted_2 = { class: "d-flex align-items-center mb-5" }
const _hoisted_3 = { class: "my-0" }
const _hoisted_4 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_5 = { class: "svg-icon svg-icon-2" }
const _hoisted_6 = { class: "mb-6" }
const _hoisted_7 = { class: "d-flex align-items-center mb-5" }
const _hoisted_8 = {
  href: "#",
  class: "btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
}
const _hoisted_9 = { class: "svg-icon svg-icon-3" }
const _hoisted_10 = {
  href: "#",
  class: "btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "position-relative mb-6" }
const _hoisted_13 = { class: "position-absolute top-0 end-0 me-n5" }
const _hoisted_14 = { class: "btn btn-icon btn-sm btn-active-color-primary pe-0 me-2" }
const _hoisted_15 = { class: "svg-icon svg-icon-3 mb-3" }
const _hoisted_16 = { class: "btn btn-icon btn-sm btn-active-color-primary ps-0" }
const _hoisted_17 = { class: "svg-icon svg-icon-2 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex align-items-center flex-grow-1\"><div class=\"symbol symbol-45px me-5\"><img src=\"media/avatars/300-13.jpg\" alt=\"\"></div><div class=\"d-flex flex-column\"><a href=\"#\" class=\"text-gray-800 text-hover-primary fs-6 fw-bolder\">Mat Dillon</a><span class=\"text-gray-400 fw-bold\">Last month</span></div></div>", 1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
            ])
          ]),
          _createVNode(_component_Dropdown3)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-gray-800 fs-6 fw-normal mb-5" }, " Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors ", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-5" }, [
          _createElementVNode("iframe", {
            class: "embed-responsive-item rounded h-300px w-100",
            src: "https://www.youtube.com/embed/qIHXpnASPAA",
            allowfullscreen: ""
          })
        ], -1)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("a", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com012.svg" })
            ]),
            _cache[1] || (_cache[1] = _createTextVNode(" 189 "))
          ]),
          _createElementVNode("a", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen030.svg" })
            ]),
            _cache[2] || (_cache[2] = _createTextVNode(" 229 "))
          ])
        ])
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "separator mb-4" }, null, -1)),
      _createElementVNode("form", _hoisted_12, [
        _cache[5] || (_cache[5] = _createElementVNode("textarea", {
          class: "form-control border-0 p-0 pe-10 resize-none min-h-25px",
          "data-kt-autosize": "true",
          rows: "1",
          placeholder: "Reply.."
        }, null, -1)),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com008.svg" })
            ])
          ]),
          _createElementVNode("span", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen018.svg" })
            ])
          ])
        ])
      ])
    ])
  ], 2))
}