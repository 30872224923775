import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px",
  "data-kt-menu": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"menu-item px-3\"><div class=\"menu-content fs-6 text-dark fw-bolder px-3 py-4\"> Quick Actions </div></div><div class=\"separator mb-3 opacity-75\"></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\"> New Ticket </a></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\"> New Customer </a></div><div class=\"menu-item px-3\" data-kt-menu-trigger=\"hover\" data-kt-menu-placement=\"right-start\" data-kt-menu-flip=\"left-start, top\"><a href=\"#\" class=\"menu-link px-3\"><span class=\"menu-title\">New Group</span><span class=\"menu-arrow\"></span></a><div class=\"menu-sub menu-sub-dropdown w-175px py-4\"><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\"> Admin Group </a></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\"> Staff Group </a></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\"> Member Group </a></div></div></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\"> New Contact </a></div><div class=\"separator mt-3 opacity-75\"></div><div class=\"menu-item px-3\"><div class=\"menu-content px-3 py-3\"><a class=\"btn btn-primary btn-sm px-4\" href=\"#\"> Generate Reports </a></div></div>", 8)
  ])))
}