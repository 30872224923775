import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body pb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "my-0" }
const _hoisted_4 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_5 = { class: "svg-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex align-items-center flex-grow-1\"><div class=\"symbol symbol-45px me-5\"><img src=\"media/avatars/300-6.jpg\" alt=\"\"></div><div class=\"d-flex flex-column\"><a href=\"#\" class=\"text-gray-800 text-hover-primary fs-6 fw-bolder\">Grace Green</a><span class=\"text-gray-400 fw-bold\">PHP, SQLite, Artisan CLI</span></div></div>", 1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
            ])
          ]),
          _createVNode(_component_Dropdown2)
        ])
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<form id=\"kt_forms_widget_1_form\" class=\"ql-quil ql-quil-plain pb-3\"><div id=\"kt_forms_widget_1_editor\" class=\"py-6\"></div><div class=\"separator\"></div><div id=\"kt_forms_widget_1_editor_toolbar\" class=\"ql-toolbar d-flex flex-stack py-2\"><div class=\"me-2\"><span class=\"ql-formats ql-size ms-0\"><select class=\"ql-size w-75px\"></select></span><span class=\"ql-formats\"><button class=\"ql-bold\"></button><button class=\"ql-italic\"></button><button class=\"ql-underline\"></button><button class=\"ql-strike\"></button><button class=\"ql-image\"></button><button class=\"ql-link\"></button><button class=\"ql-clean\"></button></span></div><div class=\"me-n3\"><span class=\"btn btn-icon btn-sm btn-active-color-primary\"><i class=\"flaticon2-clip-symbol icon-ms\"></i></span><span class=\"btn btn-icon btn-sm btn-active-color-primary\"><i class=\"flaticon2-pin icon-ms\"></i></span></div></div></form>", 1))
    ])
  ], 2))
}